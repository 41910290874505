
.footer{
    height: 45vh;
    width: 100%;
    background-color: #011a30;
    display: flex;
    flex-direction: column;
  }
  .footerTitle{
    width: 30vw;
    
  }
  .footerTitle h2{
    font-family: 'Arimo', sans-serif;
    color: #eff8ff;
    font-weight: bold;
    margin: 10px 0 0 10px;
  }
  .footerTitle hr{
    width: 80px;
    margin: 5px 0 0 30px;
    border: 1.5px solid #ff2020;
  }
  
  .footerLinks{
    display: flex;
    width: 100%;
    /* background-color: #017ed8; */
    height: 100%;
    align-items: center;
    justify-content: space-between;
  }
  .footerLinks ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    /* margin-right: 100px; */
    margin: 0 7vw;
    row-gap: 3vh;
  }
  .footerLinks ul li{
    color: #979797;
    font-family: 'Arimo', sans-serif;
    cursor: pointer;
  }
  .footerLinks ul li a{
    color: #979797;
    text-decoration: none;
  }
  .footerLinks ul li a:hover{
    color: #dcdada;
  }
  .footerCopyRights{
    display: flex;
    flex-direction:column;
    text-align: center;
  }
  .footerCopyRights hr{
    margin: 10px 30px;
    border-color: #868686;
  }
  .footerCopyRights p{
    color: #d4d4d4;
  }
  
  
  @media only screen and (max-width: 600px){
    .footer{
      height: 65vh;
    }
    .footerTitle{
      width: 50vw;
      margin-top: 3vh;
    }
    
    .footerLinks{
      display: flex;
      flex-direction: column;
      width: 100%;
      /* background-color: #017ed8; */
      height: 100%;
      align-items:start;
      justify-content: start;
      gap: 15px;
      margin-top: 5vh;
    }
    .footerLinks ul {
      display: flex;
      flex-direction: column;
      list-style-type: none;
      row-gap: 15px;
    }
    .footerLinks ul li{
      color: #979797;
      font-family: 'Arimo', sans-serif;
      cursor: pointer;
    }
    .footerLinks ul li:hover{
      color: #dcdada;
    }
    .footerCopyRights{
      display: flex;
      flex-direction:column;
      text-align: center;
    }
    .footerCopyRights hr{
      margin: 10px 30px;
      border-color: #868686;
    }
    .footerCopyRights p{
      color: #d4d4d4;
    }
  }