
.events{
    width: 100%;
    background-color: #ffffff;
    padding: 5vh 0;
    display: flex;
    align-items: start;
    gap: 10px;
  }

  .eve_slider {
    margin:0 20px;
    overflow:"hidden";
    padding:2rem 0;
    height: 40vh;
    margin-bottom: 5vh;
  }

  .eve_slider p {
    font-size: 0.8rem;
    font-weight: 500;
    text-align: center;
    height: 10vh;
  }
  
  .eve_slider img {
    width: 100%;
    height: 40vh;
    border-radius:10px;
  }
  
  .events img{
    height: 30vh;
  }
  
  
  #eventsHr1{
    width: 40vw !important;
    height: 0px;
    border: 1px solid #b0b1b3;
  }
  #eventsHr2{
    width: 3vw !important;
    height: 0px;
    border: 1px solid #b0b1b3;
  }
  
  .eventsTotalContent{
    width: 70vw;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .eventsTotalContent .title{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
    /* margin-bottom: 5vh; */
  }
  .eventsTotalContent .title h1{
    margin: 0;
    margin-left: 30px;
    font-family: 'Arimo', sans-serif;
    color: #03395f;
    font-weight: bold;
  }
  
  
  .upcommingEvents{
    width: 27vw;
    margin-top: 40px;
    text-align: center;
    height: 30vh;
  }
  .upcommingEvents .title{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px;
  }
  .upcommingEvents .title h2{
    margin: 0;
    font-family: 'Arimo', sans-serif;
    color: #03395f;
    font-weight: bold;
    font-size: 1.7rem;
  }

  .upcommingEventsList{

    height: 45vh;
    overflow-y: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none;
}

.upcommingEventsList::-webkit-scrollbar {
  display: none;
}  

.upcommingEventsList span{
    list-style: none;
    display: flex;
    align-items: start;
    margin-bottom: 2vh;
}

.upcommingEventsList span p{
    width: 80%;
    text-align: justify;
    margin: 0;
    font-size: 0.9rem;
}

.upcommingEventsList .upcommingEventIcon{
    width: 10%;
    margin-top: 5px;
}

  
  
  @media only screen and ( max-width: 600px){
    .events{
      width: 100vw;
      padding-top: 5vh;
      flex-direction: column;
      justify-content: center;
    }
    
    .eventsTotalContent{
      width: 100vw;
      margin: 0;
    }
    .eventsTotalContent .title{
      display: flex;
      flex-direction: column;
      margin-bottom: 0vh;
    }
    .eventsTotalContent .title h1{
      margin-left: 0px;
      font-size: 2.2rem;
    }
    #eventsHr1{
      width: 140px !important;
      margin: 0 0 5vh 0;
      border: 1.5px solid #50555a;
    }
    
  
    .upcommingEvents{
      width: 100vw;
      height: 40vh !important;
      /* margin-top: 40px; */
      text-align: center;
    }
    .upcommingEvents .title{
      display: flex;
      flex-direction: column;
    }
    .upcommingEvents .title h2{
      font-size: 1.7rem !important;
    }
    .upcommingEventsList{
        height: 30vh;
    }
    #eventsHr2{
      width: 140px !important;
      margin: 0 0 5vh 0;
      border: 1.5px solid #50555a;
    }
  
  }
  