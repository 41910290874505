.authentication-page{
    min-height: 100vh;
    padding-top:30vh;
}

.auth-component{
    width: 40%;
    background-color: #f6fbff;
    text-align: center;
    padding: 3vh 1.5vw;
    margin: auto;
    border-radius: 0.7rem;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;

    @media screen and (max-width: 1000px){
        width: 60%;
        
    }
    @media screen and (max-width: 600px){
        width: 80%;
        
    }
    @media screen and (max-width: 450px){
        width: 86%;
        
    }
}

.auth-component h3{
    color: #76acd7;
    margin-bottom: 3vh;
}

.auth-component input{

    outline: none;
}


.auth-component label{

    color: #426078;
}

.profilePicDiv{
    text-align: left;

}

.profilePicDiv label{
    text-align: left;
}

.auth-component button{
    min-width: 35%;

}

.auth-component p{

    margin: 0;
    margin-top: 10px;
    font-size: 0.9rem;
    color: #353738c5;

}

.auth-component p span{

    color: #76acd7;
    cursor: pointer;
}