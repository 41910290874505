

/* navbar */

.navbar{
    width: 100%;
    height: 14vh;
    /* padding-right: 60px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed !important;
    overflow-y: hidden;
    z-index: 99999;
    background-color: #f2f9fa;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  
  .navbarLogo{
    display: flex;
  }
  
  .navbarLogoText{
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: center;
    margin-left: 10px;
  }
  .navbarLogoText h1{
    margin: 0;
    padding: 0;
    font-family: 'Roboto Serif', serif;
    font-size: 2.4rem;
    font-weight: bold;
    color: #055188;
    
  }
  .navbarLogoText p{
    margin: 0;
    padding: 0;
    position: relative;
    top: -5px;
    font-family: 'Arimo', sans-serif;
    text-align: center;
    font-size: 0.9rem;
    color: #055188;
  }
  .navbarLogo img{
    width: 60px;
    height: 60px;
    margin-left: 20px;
  }
  
  .navOptions ul{
    display: flex;
    list-style-type: none;
    margin: 0;
    padding-right: 1vw;
  }
  
  .navOptions ul li{
    padding-right: 40px;
    font-size: 1.2rem;
    transition: 0.3s ease-in-out;
    color: #055188;
    cursor: pointer;
  }
  
  .navOptions ul li a{
    /* color: #fff; */
    color: #055188;
    font-family: 'Arimo', sans-serif;
    text-decoration: none;

  }
  .navOptions ul li:hover{
    transform:scale(1.05);
    transition: 0.3s ease-in-out;
  }
  .navOptions .toggle{
    display: none;
  }
  
  @media only screen and (min-width: 801px){
    .toggle{
      display: none;
    }
  }
  @media only screen and (max-width: 800px){
    .navbar{
        height: 10vh !important;
        padding-right: 20px;
    }
    .navbarLogo img{
        max-width: 65px;
        max-height: 65px;
    }
    .navbarLogoText h1{
        font-size: 2.5rem !important;
        margin: 0;
    }
    .navbarLogoText p{
        font-size: 1rem !important;
    }
    .navOptions{
        z-index: 4;
        position:fixed;
        top: 11vh;
        right: 1vh;
        background-color: #000000d2;
        height: max-content;
        padding: 20px 0;
        border-radius: 1rem;
    }
  
    .navOptions ul{
        display: flex;
        flex-direction: column;
        gap: 20px;
        text-align: center;
    }
    .navOptions ul li{
      color: #f2f9fa;
    }
    .navOptions ul li a{
      color: #f2f9fa;
    }
    .toggle{
        display: contents;
    }
    #toggleMenu{
        color: #033457;
        font-size: 2.5rem;
    }
    #toggleX{
        color: #033457;
        font-size: 2.5rem;
    }
    
  }