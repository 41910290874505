/* New fonts */
@import 'bootstrap/dist/css/bootstrap.min.css';
@font-face {
  font-family: OldeEnglish;
  src: url('./fonts/old-english.woff');
}
@font-face {
  font-family: monotypeCorsiva;
  src: url('./fonts/Monotype-Corsiva.woff');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html{
  -ms-overflow-style: none; 
    scrollbar-width: none;
}

html::-webkit-scrollbar {
  display: none;
}